/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItemDto } from './OrderItemDto';
import {
    OrderItemDtoFromJSON,
    OrderItemDtoFromJSONTyped,
    OrderItemDtoToJSON,
} from './OrderItemDto';

/**
 * 
 * @export
 * @interface UpdateOrderDto
 */
export interface UpdateOrderDto {
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof UpdateOrderDto
     */
    items?: Array<OrderItemDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderDto
     */
    restaurant: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderDto
     */
    tableNumber: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    comment?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateOrderDto
     */
    additionalProperties?: object;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderDto
     */
    restaurantId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    status?: UpdateOrderDtoStatusEnum;
}


/**
 * @export
 */
export const UpdateOrderDtoStatusEnum = {
    Created: 'created',
    Preparation: 'preparation',
    Delivery: 'delivery',
    Delivered: 'delivered',
    All: 'all',
    Closed: 'closed'
} as const;
export type UpdateOrderDtoStatusEnum = typeof UpdateOrderDtoStatusEnum[keyof typeof UpdateOrderDtoStatusEnum];


/**
 * Check if a given object implements the UpdateOrderDto interface.
 */
export function instanceOfUpdateOrderDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "restaurant" in value;
    isInstance = isInstance && "tableNumber" in value;

    return isInstance;
}

export function UpdateOrderDtoFromJSON(json: any): UpdateOrderDto {
    return UpdateOrderDtoFromJSONTyped(json, false);
}

export function UpdateOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(OrderItemDtoFromJSON)),
        'restaurant': json['restaurant'],
        'tableNumber': json['tableNumber'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'additionalProperties': !exists(json, 'additional_properties') ? undefined : json['additional_properties'],
        'restaurantId': !exists(json, 'restaurantId') ? undefined : json['restaurantId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UpdateOrderDtoToJSON(value?: UpdateOrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(OrderItemDtoToJSON)),
        'restaurant': value.restaurant,
        'tableNumber': value.tableNumber,
        'code': value.code,
        'comment': value.comment,
        'additional_properties': value.additionalProperties,
        'restaurantId': value.restaurantId,
        'status': value.status,
    };
}

