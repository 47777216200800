/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductListDto
 */
export interface ProductListDto {
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductListDto
     */
    products: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    total: number;
}

/**
 * Check if a given object implements the ProductListDto interface.
 */
export function instanceOfProductListDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function ProductListDtoFromJSON(json: any): ProductListDto {
    return ProductListDtoFromJSONTyped(json, false);
}

export function ProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
        'total': json['total'],
    };
}

export function ProductListDtoToJSON(value?: ProductListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(ProductToJSON)),
        'total': value.total,
    };
}

