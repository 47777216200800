import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LanguagesInApp } from '../utils/models/Languages';
import AL from './locales/al/translations';
import CRO from './locales/cro/translations';
import EN from './locales/en/translations';
import MK from './locales/mk/translations';

i18n.use(initReactI18next).init({
  fallbackLng: LanguagesInApp.MK,
  lng: LanguagesInApp.MK,
  interpolation: {
    escapeValue: false
  },
  defaultNS: 'translations',
  resources: {
    en: {
      translations: EN
    },
    mk: {
      translations: MK
    },
    al: {
      translations: AL
    },
    cro: {
      translations: CRO
    }
  }
});

i18n.languages = [LanguagesInApp.EN, LanguagesInApp.MK, LanguagesInApp.AL, LanguagesInApp.CRO];

export default i18n;
