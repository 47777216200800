/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActiveTablesDto } from './ActiveTablesDto';
import {
    ActiveTablesDtoFromJSON,
    ActiveTablesDtoFromJSONTyped,
    ActiveTablesDtoToJSON,
} from './ActiveTablesDto';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    isclosed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    isSelfService: boolean;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    isactive: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    wifi?: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    instagram?: string;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    numberOfTables: number;
    /**
     * 
     * @type {Array<ActiveTablesDto>}
     * @memberof Restaurant
     */
    activeTables: Array<ActiveTablesDto>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Restaurant
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Restaurant
     */
    orders?: Array<Order>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Restaurant
     */
    users?: Array<User>;
}

/**
 * Check if a given object implements the Restaurant interface.
 */
export function instanceOfRestaurant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isclosed" in value;
    isInstance = isInstance && "isSelfService" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "isactive" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "numberOfTables" in value;
    isInstance = isInstance && "activeTables" in value;

    return isInstance;
}

export function RestaurantFromJSON(json: any): Restaurant {
    return RestaurantFromJSONTyped(json, false);
}

export function RestaurantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Restaurant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isclosed': json['isclosed'],
        'isSelfService': json['isSelfService'],
        'name': json['name'],
        'logo': json['logo'],
        'address': json['address'],
        'isactive': json['isactive'],
        'email': json['email'],
        'phone': json['phone'],
        'wifi': !exists(json, 'wifi') ? undefined : json['wifi'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'numberOfTables': json['numberOfTables'],
        'activeTables': ((json['activeTables'] as Array<any>).map(ActiveTablesDtoFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductFromJSON)),
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(OrderFromJSON)),
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(UserFromJSON)),
    };
}

export function RestaurantToJSON(value?: Restaurant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isclosed': value.isclosed,
        'isSelfService': value.isSelfService,
        'name': value.name,
        'logo': value.logo,
        'address': value.address,
        'isactive': value.isactive,
        'email': value.email,
        'phone': value.phone,
        'wifi': value.wifi,
        'instagram': value.instagram,
        'numberOfTables': value.numberOfTables,
        'activeTables': ((value.activeTables as Array<any>).map(ActiveTablesDtoToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductToJSON)),
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(OrderToJSON)),
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(UserToJSON)),
    };
}

