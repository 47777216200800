import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import io, { Socket } from 'socket.io-client';

import { Order, OrderStatusEnum } from '../api';
import {
  notifyWaiter,
  notifyWaiterReceipt,
  receiveOrderFromSocket,
  tableStatusUpdate
} from '../features/admin/store/adminSlice';
import { showNotification } from '../features/common/notifications/NativeNotifications';
import { getActiveOrderAsync, orderCancelled } from '../features/order/ordersSlice';
import {
  notifyClientAcceptWaiter,
  notifyClientReceipt,
  setAcceptedOrder
} from '../features/restaurant/restaurantSlice';
import i18n from '../i18n/config';
import { useAppDispatch } from '../store';
import SnackbarUtils from '../utils/snackbar/SnackbarUtils';

export function useClientSocket(restaurantId: number | undefined, tableNumber: number | undefined) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (!restaurantId) return;
    const socket: Socket = io(import.meta.env.VITE_API_URL);
    socket.connect();
    socket.on(`connect-${restaurantId}-${tableNumber}`, () => {
      console.log('Client socket connected', restaurantId);
    });

    socket.on(`order-status-update-${restaurantId}-${tableNumber}`, (orderStr: string) => {
      const order: Order = JSON.parse(orderStr);
      if (order.status == OrderStatusEnum.Preparation) {
        dispatch(setAcceptedOrder(order));
      } else if (order.status == OrderStatusEnum.Closed) {
        dispatch(orderCancelled(order));
      } else if (order.status == OrderStatusEnum.Delivered) {
        SnackbarUtils.info(t('userOrderDelivered', { clientsOrderId: order.id }));
      }
      const code = new URLSearchParams(window.location.search).get('code');
      if (!code) return;
      // Refresh the orders
      dispatch(
        getActiveOrderAsync({
          restaurantId,
          tableNumber: Number(tableNumber),
          code
        })
      );
    });

    socket.on(`notify-client-accept-waiter-${restaurantId}-${tableNumber}`, (event) => {
      dispatch(notifyClientAcceptWaiter(JSON.parse(event)));
    });

    socket.on(`notify-client-receipt-${restaurantId}-${tableNumber}`, (event) => {
      dispatch(notifyClientReceipt(JSON.parse(event)));
    });
    return () => {
      if (restaurantId) {
        socket.disconnect();
      }
    };
  }, [restaurantId, tableNumber, dispatch]);
}

export function useAdminSocket(restaurantId: number | undefined) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!restaurantId) return;
    const socket: Socket = io(`${import.meta.env.VITE_API_URL}`);
    socket.on(`connect-${restaurantId}`, () => {
      console.log('Admin socket connected to restaurant', restaurantId);
    });
    socket.on(`receive-order-${restaurantId}`, (event) => {
      const order: Order = JSON.parse(event);
      order.additionalProperties = JSON.parse(event).additional_properties;

      showNotification(
        i18n.t('adminPushNotificationOrderTitle'),
        i18n.t('adminPushNotificationOrderBody', {
          tableNumber: order.tableNumber,
          order_id: order.id
        })
      );
      dispatch(receiveOrderFromSocket(order));
    });

    socket.on(`table-status-update-${restaurantId}`, (activeTables) => {
      dispatch(tableStatusUpdate(JSON.parse(activeTables)));
    });

    socket.on(`notify-waiter-${restaurantId}`, (event) => {
      const tableNumber: number = JSON.parse(event);
      showNotification(
        i18n.t('adminPushNotificationCallWaiterTitle'),
        i18n.t('adminPushNotificationCallWaiterBody', { tableNumber })
      );
      dispatch(notifyWaiter(tableNumber));
    });

    socket.on(`notify-waiter-receipt-${restaurantId}`, (tableNumber: string) => {
      showNotification(
        i18n.t('adminPushNotificationCallReceiptTitle'),
        i18n.t('adminPushNotificationCallReceiptBody', { tableNumber })
      );
      dispatch(notifyWaiterReceipt(Number(tableNumber)));
    });
    return () => {
      if (restaurantId) {
        socket.disconnect();
      }
    };
  }, [restaurantId, dispatch]);
}
