/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiKey,
  ApiKeyDTO,
  CreateApiKeyDTO,
  User,
  UserDTO,
} from '../models';
import {
    ApiKeyFromJSON,
    ApiKeyToJSON,
    ApiKeyDTOFromJSON,
    ApiKeyDTOToJSON,
    CreateApiKeyDTOFromJSON,
    CreateApiKeyDTOToJSON,
    UserFromJSON,
    UserToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
} from '../models';

export interface UsersControllerCreateApiKeyRequest {
    createApiKeyDTO: CreateApiKeyDTO;
}

export interface UsersControllerCreateUserRequest {
    userDTO: UserDTO;
}

export interface UsersControllerDeleteApiKeyRequest {
    id: number;
}

export interface UsersControllerGetApiKeyRequest {
    apiKeyDTO: ApiKeyDTO;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async usersControllerCreateApiKeyRaw(requestParameters: UsersControllerCreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiKeyDTO>> {
        if (requestParameters.createApiKeyDTO === null || requestParameters.createApiKeyDTO === undefined) {
            throw new runtime.RequiredError('createApiKeyDTO','Required parameter requestParameters.createApiKeyDTO was null or undefined when calling usersControllerCreateApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users/api-keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateApiKeyDTOToJSON(requestParameters.createApiKeyDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyDTOFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerCreateApiKey(requestParameters: UsersControllerCreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiKeyDTO> {
        const response = await this.usersControllerCreateApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerCreateUserRaw(requestParameters: UsersControllerCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userDTO === null || requestParameters.userDTO === undefined) {
            throw new runtime.RequiredError('userDTO','Required parameter requestParameters.userDTO was null or undefined when calling usersControllerCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDTOToJSON(requestParameters.userDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerCreateUser(requestParameters: UsersControllerCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.usersControllerCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerDeleteApiKeyRaw(requestParameters: UsersControllerDeleteApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersControllerDeleteApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/api-keys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerDeleteApiKey(requestParameters: UsersControllerDeleteApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersControllerDeleteApiKeyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersControllerGetApiKeyRaw(requestParameters: UsersControllerGetApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiKeyDTO>> {
        if (requestParameters.apiKeyDTO === null || requestParameters.apiKeyDTO === undefined) {
            throw new runtime.RequiredError('apiKeyDTO','Required parameter requestParameters.apiKeyDTO was null or undefined when calling usersControllerGetApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users/api-keys-not-used`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiKeyDTOToJSON(requestParameters.apiKeyDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyDTOFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerGetApiKey(requestParameters: UsersControllerGetApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiKeyDTO> {
        const response = await this.usersControllerGetApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetApiKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiKey>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/api-keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiKeyFromJSON));
    }

    /**
     */
    async usersControllerGetApiKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiKey>> {
        const response = await this.usersControllerGetApiKeysRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async usersControllerGetUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.usersControllerGetUsersRaw(initOverrides);
        return await response.value();
    }

}
