import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

enum SeverityLevel {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error'
}

let useSnackbarRef;
let translations;
export const SnackbarUtilsConfigurator = (): null => {
  useSnackbarRef = useSnackbar();
  const translation = useTranslation();
  translations = translation.t;
  return null;
};

const SnackbarUtils = {
  success(msg: string): void {
    this.toast(msg, SeverityLevel.SUCCESS);
  },
  warning(msg: string): void {
    this.toast(msg, SeverityLevel.WARNING);
  },
  info(msg: string): void {
    this.toast(msg, SeverityLevel.INFO);
  },
  error(msg: string): void {
    this.toast(msg, SeverityLevel.ERROR);
  },
  toast(msg: string, severity: SeverityLevel = SeverityLevel.SUCCESS): void {
    const key = useSnackbarRef.enqueueSnackbar(
      <Card raised sx={{ width: '100%' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {translations('newNotification')}
          </Typography>
          <Typography color="text.secondary">{msg}</Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={() => useSnackbarRef.closeSnackbar(key)}>
            OK
          </Button>
        </CardActions>
      </Card>,
      {
        SnackbarProps: {
          onClick: () => useSnackbarRef.closeSnackbar(key)
        }
      }
    );
  }
};

export function SnackbarAlert({
  msg,
  severity
}: {
  msg: JSX.Element;
  severity: SeverityLevel;
}): JSX.Element | null {
  useEffect(() => {
    SnackbarUtils.toast(msg, severity);
  }, []);
  return null;
}

export default SnackbarUtils;
