/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveTablesDto
 */
export interface ActiveTablesDto {
    /**
     * 
     * @type {number}
     * @memberof ActiveTablesDto
     */
    table: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveTablesDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActiveTablesDto
     */
    code: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiveTablesDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveTablesDto
     */
    triggerTable?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActiveTablesDto
     */
    triggerSector?: number | null;
}

/**
 * Check if a given object implements the ActiveTablesDto interface.
 */
export function instanceOfActiveTablesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "table" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function ActiveTablesDtoFromJSON(json: any): ActiveTablesDto {
    return ActiveTablesDtoFromJSONTyped(json, false);
}

export function ActiveTablesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveTablesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'table': json['table'],
        'active': json['active'],
        'code': json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'triggerTable': !exists(json, 'triggerTable') ? undefined : json['triggerTable'],
        'triggerSector': !exists(json, 'triggerSector') ? undefined : json['triggerSector'],
    };
}

export function ActiveTablesDtoToJSON(value?: ActiveTablesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'table': value.table,
        'active': value.active,
        'code': value.code,
        'name': value.name,
        'triggerTable': value.triggerTable,
        'triggerSector': value.triggerSector,
    };
}

