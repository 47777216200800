import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { Product } from '../../api';
import { RootState } from '../../store';
import { ApiStatus } from '../../utils/models/ApiStatus';
import { getProductsForRestaurant } from './api/productsApi';

interface ProductsState {
  status: ApiStatus;
  items: Product[];
  total: number;
  activeCategory: string;
  productType?: string;
  productQuery: string;
}

const initialState: ProductsState = {
  status: ApiStatus.IDLE,
  items: [],
  total: 0,
  activeCategory: 'all',
  productType: undefined,
  productQuery: ''
};

export const getProductsForRestaurantAsync = createAsyncThunk(
  'products/getProductsForRestaurant',
  async ({ restaurantId, lang }: { restaurantId: number; lang: string }) => {
    const response = await getProductsForRestaurant(restaurantId, lang);
    return response;
  }
);

const productsSlice = createSlice({
  name: 'Products Slice',
  initialState,
  reducers: {
    clearError: (state) => {
      state.status = ApiStatus.IDLE;
    },
    setActiveCategory: (state, { payload }: PayloadAction<string>) => {
      state.activeCategory = payload;
    },
    setProductType: (state, { payload }: PayloadAction<string>) => {
      state.productType = payload;
    },
    setProductQuery: (state, { payload }: PayloadAction<string>) => {
      state.productQuery = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsForRestaurantAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(getProductsForRestaurantAsync.fulfilled, (state, { payload }) => {
      state.status = ApiStatus.IDLE;
      state.items = payload.products || [];
      state.total = payload.total || 0;
    });
    builder.addCase(getProductsForRestaurantAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
    });
  }
});

export const { clearError, setActiveCategory, setProductType, setProductQuery } =
  productsSlice.actions;

export const selectProductsStatus = ({ products }: RootState): ApiStatus => products.status;
export const selectProducts = ({ products }: RootState): Product[] => products.items;
export const selectProductsCount = ({ products }: RootState): number => products.total;
export const selectProductType = ({ products }: RootState): string => products.productType;
export const selectProductQuery = ({ products }: RootState): string => products.productQuery;
export const selectActiveCategory = ({ products }: RootState): string => products.activeCategory;

// Hardcoding MK sub categories for quick sorting;
// TODO: Move this to backend
export const sortedSubTypes: Record<string, number> = {
  // Drinks
  'Авторски коктели': 1,
  'Класични коктели': 2,
  Водка: 3,
  Вино: 4,
  Текила: 5,
  Пиво: 6,
  Виски: 7,
  Џин: 8,
  Бурбон: 9,
  Рум: 10,
  Кафе: 11,
  Сокови: 12,
  Коњак: 13,
  'Коктел во шише': 14,
  // Food
  Храна: 15,
  'Мис Стон': 16
};

export const selectGroupedProductSubTypes = createSelector([selectProducts], (products) => {
  const groupProducts = products.reduce((r: any, a: any) => {
    r[a.type] = r[a.type] || [];
    if (!r[a.type].includes(a.subType)) {
      r[a.type].push(a.subType);
    }
    return r;
  }, {});
  // sort groupProducts by sortedSubTypes
  Object.keys(groupProducts).forEach((type) => {
    groupProducts[type] = groupProducts[type].sort((a: string, b: string) => {
      return (sortedSubTypes[b] ?? 999) > (sortedSubTypes[a] ?? 999) ? -1 : 1;
    });
  });
  return groupProducts;
});

export const selectGroupedProducts = createSelector(
  [selectProducts, selectActiveCategory, selectProductQuery],
  (products, category, query) => {
    const groupProducts = products.reduce((r: any, a: any) => {
      if (query.length === 0 || a.name.toLowerCase().includes(query.toLowerCase())) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
      }
      return r;
    }, {});

    Object.keys(groupProducts).forEach((type) => {
      if (category !== 'all' && groupProducts[type].length > 0) {
        const filteredProducts = groupProducts[type].filter(
          (product: any) => product.subType === category
        );
        groupProducts[type] = sortBy(filteredProducts, 'name');
      } else {
        // when in "all" category, sort by respecting the sortedSubTypes
        groupProducts[type] = groupProducts[type]?.sort((a: any, b: any) => {
          return (sortedSubTypes[b.subType] ?? 999) > (sortedSubTypes[a.subType] ?? 999) ? -1 : 1;
        });
      }
    });

    return groupProducts;
  }
);

export const selectFilteredProductsByCategory = createSelector(
  [selectProducts, selectActiveCategory],
  (products, category) => {
    return category === 'all'
      ? products
      : products.filter((product) => product.subType === category);
  }
);

export default productsSlice.reducer;
