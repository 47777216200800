/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckPasswordDto,
  CreateUserDto,
  JWTResponseDTO,
  LoginCredentials,
} from '../models';
import {
    CheckPasswordDtoFromJSON,
    CheckPasswordDtoToJSON,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    JWTResponseDTOFromJSON,
    JWTResponseDTOToJSON,
    LoginCredentialsFromJSON,
    LoginCredentialsToJSON,
} from '../models';

export interface AuthenticationControllerCheckPasswordRequest {
    checkPasswordDto: CheckPasswordDto;
}

export interface AuthenticationControllerLoginRequest {
    loginCredentials: LoginCredentials;
}

export interface AuthenticationControllerRegisterRequest {
    createUserDto: CreateUserDto;
}

export interface AuthenticationControllerUpdateUserRequest {
    createUserDto: CreateUserDto;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     */
    async authenticationControllerCheckPasswordRaw(requestParameters: AuthenticationControllerCheckPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.checkPasswordDto === null || requestParameters.checkPasswordDto === undefined) {
            throw new runtime.RequiredError('checkPasswordDto','Required parameter requestParameters.checkPasswordDto was null or undefined when calling authenticationControllerCheckPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/authentication/checkPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckPasswordDtoToJSON(requestParameters.checkPasswordDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async authenticationControllerCheckPassword(requestParameters: AuthenticationControllerCheckPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.authenticationControllerCheckPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authenticationControllerLoginRaw(requestParameters: AuthenticationControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JWTResponseDTO>> {
        if (requestParameters.loginCredentials === null || requestParameters.loginCredentials === undefined) {
            throw new runtime.RequiredError('loginCredentials','Required parameter requestParameters.loginCredentials was null or undefined when calling authenticationControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/authentication/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCredentialsToJSON(requestParameters.loginCredentials),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JWTResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authenticationControllerLogin(requestParameters: AuthenticationControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JWTResponseDTO> {
        const response = await this.authenticationControllerLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authenticationControllerRegisterRaw(requestParameters: AuthenticationControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.createUserDto === null || requestParameters.createUserDto === undefined) {
            throw new runtime.RequiredError('createUserDto','Required parameter requestParameters.createUserDto was null or undefined when calling authenticationControllerRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/authentication/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async authenticationControllerRegister(requestParameters: AuthenticationControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.authenticationControllerRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authenticationControllerUpdateUserRaw(requestParameters: AuthenticationControllerUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createUserDto === null || requestParameters.createUserDto === undefined) {
            throw new runtime.RequiredError('createUserDto','Required parameter requestParameters.createUserDto was null or undefined when calling authenticationControllerUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/authentication/updateUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authenticationControllerUpdateUser(requestParameters: AuthenticationControllerUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authenticationControllerUpdateUserRaw(requestParameters, initOverrides);
    }

}
