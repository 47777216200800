import { SnackbarProvider as NotistackSnackbarProvider, SnackbarContent } from 'notistack';
import React from 'react';

const SnackbarWrapper = React.forwardRef<HTMLDivElement, { message: string | React.ReactNode }>(
  (props, ref) => {
    return <SnackbarContent ref={ref}>{props.message}</SnackbarContent>;
  }
);
SnackbarWrapper.displayName = 'SnackbarWrapper';

export const SnackbarProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const isAdmin = window.location.pathname.includes('admin');
  return (
    <NotistackSnackbarProvider
      // eslint-disable-next-line react/no-unstable-nested-components
      content={(key, message) => <SnackbarWrapper key={key} message={message} />}
      autoHideDuration={10000}
      anchorOrigin={
        isAdmin
          ? { horizontal: 'left', vertical: 'bottom' }
          : { horizontal: 'center', vertical: 'top' }
      }
      maxSnack={3}>
      {children}
    </NotistackSnackbarProvider>
  );
};
