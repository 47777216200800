import { ClerkProvider, SignedIn, useAuth } from '@clerk/clerk-react';
import { TokenStore } from '@root/config/apiConfig';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function AfterAuth() {
  const { getToken } = useAuth();
  useEffect(() => {
    (async () => {
      TokenStore.setTokenFetcher(getToken);
    })();
  }, [getToken]);

  return null;
}

export default function AuthWrapperAuthWrapper({
  children
}: {
  children?: React.ReactElement | React.ReactElement[];
}) {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      navigate={navigate}
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      supportEmail="support@any-menu.com"
      allowedRedirectOrigins={['any-menu.com', 'localhost']}
      afterSignInUrl="/admin/dashboard"
      afterSignUpUrl="/admin/dashboard"
      signInUrl="/admin/login"
      appearance={{
        variables: { colorPrimary: '#4c8f6a' }
      }}>
      <SignedIn>
        <AfterAuth />
      </SignedIn>
      <Outlet />
      {children}
    </ClerkProvider>
  );
}
