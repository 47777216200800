/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrdersControllerNotifyWaiterRequest
 */
export interface OrdersControllerNotifyWaiterRequest {
    /**
     * 
     * @type {string}
     * @memberof OrdersControllerNotifyWaiterRequest
     */
    status?: OrdersControllerNotifyWaiterRequestStatusEnum;
}


/**
 * @export
 */
export const OrdersControllerNotifyWaiterRequestStatusEnum = {
    Created: 'created'
} as const;
export type OrdersControllerNotifyWaiterRequestStatusEnum = typeof OrdersControllerNotifyWaiterRequestStatusEnum[keyof typeof OrdersControllerNotifyWaiterRequestStatusEnum];


/**
 * Check if a given object implements the OrdersControllerNotifyWaiterRequest interface.
 */
export function instanceOfOrdersControllerNotifyWaiterRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrdersControllerNotifyWaiterRequestFromJSON(json: any): OrdersControllerNotifyWaiterRequest {
    return OrdersControllerNotifyWaiterRequestFromJSONTyped(json, false);
}

export function OrdersControllerNotifyWaiterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrdersControllerNotifyWaiterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function OrdersControllerNotifyWaiterRequestToJSON(value?: OrdersControllerNotifyWaiterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

