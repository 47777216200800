import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import notFoundBackground from '../../assets/notfound.png';
import { useTranslation } from 'react-i18next';

const ErrorPageContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
  display: 'grid',
  placeContent: 'center'
}));

const Image = styled('img')({
  height: 'auto',
  width: '100%'
});

const Title = styled('h1')({
  textAlign: 'center'
});

export function ErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ErrorPageContainer>
      <Image src={notFoundBackground} alt="not found image" />
      <Title>{t('errorPageDesc')}</Title>
      <Button variant="contained" onClick={() => navigate({ pathname: '/' })}>
        {t('errorPageButtonBack')}
      </Button>
    </ErrorPageContainer>
  );
}
