import { configureStore } from '@reduxjs/toolkit';

import admin from '../features/admin/store/adminSlice';
import orders from '../features/order/ordersSlice';
import products from '../features/products/productsSlice';
import restaurants from '../features/restaurant/restaurantSlice';
import { snackbarMiddleware } from '../middleware/snackbarMiddleware';

const isAdmin = window.location.pathname.includes('admin');

type ReducerType = {
  admin: typeof admin;
  products: typeof products;
  orders: typeof orders;
  restaurants: typeof restaurants;
};

const reducer: Partial<ReducerType> = {
  products,
  orders,
  restaurants
};

if (isAdmin) {
  reducer.admin = admin;
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([snackbarMiddleware])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
