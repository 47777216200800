import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { IconButton, Tooltip } from '@mui/material';
import SnackbarUtils from '@root/utils/snackbar/SnackbarUtils';
import { useState } from 'react';

const activeAudios: HTMLAudioElement[] = [];
export const stopNotifications = () => {
  activeAudios.forEach((audio) => audio.pause());
};

export const playAlert = () => {
  // Do not stack them cause its annoying
  if (activeAudios.length > 0) {
    return;
  }
  const audio = new Audio('/alert.wav');
  audio.loop = true;
  audio.play();
  activeAudios.push(audio);
  return audio;
};

export const showNotification = (
  title: string,
  subtitle: string,
  options?: NotificationOptions
) => {
  new Notification(title, {
    ...options,
    body: subtitle,
    badge: '/favicon.png',
    icon: '/favicon.png'
    // requireInteraction: true,
  });
  SnackbarUtils.info([title, subtitle].join('. '));
  playAlert();
};

const NativeNotifications = (): JSX.Element | null => {
  const supportsNativeNotifications = 'Notification' in window;
  const [notificationsEnabled, setNotifcationEnabled] = useState(
    supportsNativeNotifications ? Notification.permission === 'granted' : false
  );
  if (!supportsNativeNotifications) {
    console.warn('Native notifications are not supported in this browser');
    return null;
  }

  function requestPermission() {
    if (notificationsEnabled) {
      // Also stop the sounds, because intuitively the user would expect the sound to stop
      stopNotifications();
      console.log('Already enabled');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setNotifcationEnabled(true);
          SnackbarUtils.info(
            'Notifications are enabled. You will receive notifications for new orders'
          );
        }
      });
    }
  }
  return (
    <Tooltip
      describeChild
      title={notificationsEnabled ? 'Notifications are enabled' : 'Notifications are disabled'}>
      <IconButton
        aria-label="Enable/Disable notifications"
        onClick={requestPermission}
        color="inherit">
        {notificationsEnabled ? <NotificationsNoneIcon /> : <NotificationsOffIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default NativeNotifications;
