/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Addon } from './Addon';
import {
    AddonFromJSON,
    AddonFromJSONTyped,
    AddonToJSON,
} from './Addon';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * 
     * @type {Product}
     * @memberof OrderItemDto
     */
    product: Product;
    /**
     * 
     * @type {Array<Addon>}
     * @memberof OrderItemDto
     */
    addons?: Array<Addon>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemDto
     */
    quantity: number;
}

/**
 * Check if a given object implements the OrderItemDto interface.
 */
export function instanceOfOrderItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function OrderItemDtoFromJSON(json: any): OrderItemDto {
    return OrderItemDtoFromJSONTyped(json, false);
}

export function OrderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': ProductFromJSON(json['product']),
        'addons': !exists(json, 'addons') ? undefined : ((json['addons'] as Array<any>).map(AddonFromJSON)),
        'quantity': json['quantity'],
    };
}

export function OrderItemDtoToJSON(value?: OrderItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': ProductToJSON(value.product),
        'addons': value.addons === undefined ? undefined : ((value.addons as Array<any>).map(AddonToJSON)),
        'quantity': value.quantity,
    };
}

