import { Box, styled } from '@mui/material';

import { Router } from './routes/Router';

const AppContainer = styled(Box)({
  height: '99%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto'
});

function App() {
  return (
    <AppContainer>
      <Router />
      {/* <Footer /> */}
    </AppContainer>
  );
}

export default App;
