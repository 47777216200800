/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItemDto } from './OrderItemDto';
import {
    OrderItemDtoFromJSON,
    OrderItemDtoFromJSONTyped,
    OrderItemDtoToJSON,
} from './OrderItemDto';

/**
 * 
 * @export
 * @interface CreateOrderAPIDto
 */
export interface CreateOrderAPIDto {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderAPIDto
     */
    restaurant: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderAPIDto
     */
    tableNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderAPIDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderAPIDto
     */
    comment?: string;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof CreateOrderAPIDto
     */
    items: Array<OrderItemDto>;
}

/**
 * Check if a given object implements the CreateOrderAPIDto interface.
 */
export function instanceOfCreateOrderAPIDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "restaurant" in value;
    isInstance = isInstance && "tableNumber" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function CreateOrderAPIDtoFromJSON(json: any): CreateOrderAPIDto {
    return CreateOrderAPIDtoFromJSONTyped(json, false);
}

export function CreateOrderAPIDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderAPIDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'restaurant': json['restaurant'],
        'tableNumber': json['tableNumber'],
        'code': json['code'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'items': ((json['items'] as Array<any>).map(OrderItemDtoFromJSON)),
    };
}

export function CreateOrderAPIDtoToJSON(value?: CreateOrderAPIDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'restaurant': value.restaurant,
        'tableNumber': value.tableNumber,
        'code': value.code,
        'comment': value.comment,
        'items': ((value.items as Array<any>).map(OrderItemDtoToJSON)),
    };
}

