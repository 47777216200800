/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActiveTablesDto } from './ActiveTablesDto';
import {
    ActiveTablesDtoFromJSON,
    ActiveTablesDtoFromJSONTyped,
    ActiveTablesDtoToJSON,
} from './ActiveTablesDto';

/**
 * 
 * @export
 * @interface CreateRestaurantDto
 */
export interface CreateRestaurantDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    logo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    isactive?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRestaurantDto
     */
    isclosed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRestaurantDto
     */
    isSelfService?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateRestaurantDto
     */
    numberOfTables: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestaurantDto
     */
    wifi?: string;
    /**
     * 
     * @type {Array<ActiveTablesDto>}
     * @memberof CreateRestaurantDto
     */
    activeTables: Array<ActiveTablesDto>;
}

/**
 * Check if a given object implements the CreateRestaurantDto interface.
 */
export function instanceOfCreateRestaurantDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "numberOfTables" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "activeTables" in value;

    return isInstance;
}

export function CreateRestaurantDtoFromJSON(json: any): CreateRestaurantDto {
    return CreateRestaurantDtoFromJSONTyped(json, false);
}

export function CreateRestaurantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRestaurantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'logo': json['logo'],
        'address': json['address'],
        'email': json['email'],
        'isactive': !exists(json, 'isactive') ? undefined : json['isactive'],
        'isclosed': !exists(json, 'isclosed') ? undefined : json['isclosed'],
        'isSelfService': !exists(json, 'isSelfService') ? undefined : json['isSelfService'],
        'numberOfTables': json['numberOfTables'],
        'phone': json['phone'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'wifi': !exists(json, 'wifi') ? undefined : json['wifi'],
        'activeTables': ((json['activeTables'] as Array<any>).map(ActiveTablesDtoFromJSON)),
    };
}

export function CreateRestaurantDtoToJSON(value?: CreateRestaurantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'logo': value.logo,
        'address': value.address,
        'email': value.email,
        'isactive': value.isactive,
        'isclosed': value.isclosed,
        'isSelfService': value.isSelfService,
        'numberOfTables': value.numberOfTables,
        'phone': value.phone,
        'instagram': value.instagram,
        'wifi': value.wifi,
        'activeTables': ((value.activeTables as Array<any>).map(ActiveTablesDtoToJSON)),
    };
}

