import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { Order, OrderItemDto, Product } from '../../api';
import { RootState } from '../../store';
import { ApiStatus } from '../../utils/models/ApiStatus';
import { ProductOption } from '../products/components/Product/types';
import { fetchActiveOrder, notifyWaiter, submitCart } from './api/ordersApi';

export interface OrderItem {
  product: Product;
  addons: ProductOption[];
  quantity: number;
}

export function getOrderItemKey(item: OrderItem) {
  const { product, addons } = item;
  return `${product.id}-${addons.map((addon) => addon.name).join('-')}`;
}

interface OrderState {
  status: ApiStatus;
  items: Record<string, OrderItem>;
  hasActiveOrder: boolean;
  tableOrders: any;
}

const initialState: OrderState = {
  status: ApiStatus.IDLE,
  items: {},
  hasActiveOrder: false,
  tableOrders: null
};

export const submitCartAsync = createAsyncThunk(
  'orders/submitCart',
  async (
    {
      tableNumber,
      code,
      orderComment
    }: {
      tableNumber: number;
      code: string;
      orderComment: string;
    },
    { getState }
  ) => {
    const { restaurants, orders } = getState() as RootState;
    const remappedItems: OrderItemDto[] = Object.values(orders.items);
    if (restaurants.selectedRestaurantId) {
      await submitCart({
        restaurant: restaurants.selectedRestaurantId,
        tableNumber,
        code,
        items: remappedItems,
        comment: orderComment
      });
    }
  }
);

export const notifyWaiterAsync = createAsyncThunk('orders/notifyWaiter', notifyWaiter);
export const getActiveOrderAsync = createAsyncThunk(
  'orders/getActiveOrder',
  async ({
    restaurantId,
    tableNumber,
    code
  }: {
    restaurantId: number;
    tableNumber: number;
    code: string;
  }) => {
    if (!code || !restaurantId) {
      return Promise.reject('Missing params');
    }

    const response = await fetchActiveOrder(tableNumber, restaurantId, code);
    return response;
  }
);

const ordersSlice = createSlice({
  name: 'Orders Slice',
  initialState,
  reducers: {
    clearError: (state) => {
      state.status = ApiStatus.IDLE;
    },
    removeAllItems: (state) => {
      state.items = {};
    },
    orderCancelled: (state, { payload }) => {
      if (state.tableOrders) {
        const index = state.tableOrders.findIndex((o) => o.id === payload.id);
        if (index !== -1) {
          state.tableOrders[index] = payload;
        }
      }
    },
    setOrderItemInCart: (state, { payload }: PayloadAction<OrderItem>) => {
      const { quantity } = payload;
      const key = getOrderItemKey(payload);
      const existingProductIndex = state.items[key];
      if (existingProductIndex) {
        state.items[key].quantity = quantity;
        // remove item if quantity is 0
        if (state.items[key].quantity <= 0) {
          delete state.items[key];
        }
      } else {
        // new item
        state.items[key] = payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submitCartAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(submitCartAsync.fulfilled, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(submitCartAsync.rejected, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(getActiveOrderAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(
      getActiveOrderAsync.fulfilled,
      (state, { payload }: PayloadAction<Order | unknown>) => {
        state.status = ApiStatus.IDLE;
        state.hasActiveOrder = true;
        state.tableOrders = (payload as Order[]).filter(
          (order) => order.items && order.items.length > 0
        );
      }
    );
    builder.addCase(getActiveOrderAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
      state.hasActiveOrder = false;
    });
    builder.addCase(notifyWaiterAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(notifyWaiterAsync.fulfilled, (state) => {
      state.status = ApiStatus.IDLE;
    });
    builder.addCase(notifyWaiterAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
    });
  }
});

export const { clearError, setOrderItemInCart, removeAllItems, orderCancelled } =
  ordersSlice.actions;

export const selectOrderStatus = ({ orders }: RootState) => orders.status;
const _selectOrderItems = ({ orders }: RootState) => orders.items;
export const selectOrderItems = createSelector([_selectOrderItems], (items) =>
  Object.values(items)
);

export const selectTableOrders = ({ orders }: RootState) => orders.tableOrders;

export default ordersSlice.reducer;
