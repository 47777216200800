import { Configuration, RequestContext } from '../api/runtime';
// This will be used to store the token in the context of the application
export const TokenStore: {
  tokenFetcher: () => string | null;
  setTokenFetcher: (tokenFetcher: () => string) => void;
} = {
  tokenFetcher: () => null,
  setTokenFetcher: function (tokenFetcher: () => string) {
    this.tokenFetcher = tokenFetcher;
  }
};
const configuration = new Configuration({
  basePath: import.meta.env.VITE_API_URL,
  // When developing locally the server runs on a different origin, localhost:3000, while the client runs on localhost:4200
  credentials: import.meta.env.VITE_API_URL.includes('localhost') ? 'include' : 'same-origin',
  middleware: [
    {
      pre: async (ctx: RequestContext) => {
        const accessToken = await TokenStore.tokenFetcher();
        return {
          ...ctx,
          init: {
            ...ctx.init,
            headers: {
              ...ctx.init.headers,
              Authorization: `Bearer ${accessToken}`
            }
          }
        };
      }
    }
  ]
});

export default configuration;
