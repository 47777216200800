import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  useParams,
  useSearchParams
} from 'react-router-dom';

import AuthWrapper from '../features/authentication/AuthWrapper';
import { ErrorPage } from '../features/ErrorPage/ErrorPage';
import RestaurantRedirect from '../features/restaurant/components/RestaurantRedirect';
import { useClientSocket } from '../middleware/socketMiddleware';
import { Header } from '../ui/Header/Header';
import PrivateRoute from './PrivateRoute';
import { RouterNames } from './RouteNames';
import { SuspenseLoader } from './SuspenseLoader';

const Landing = React.lazy(() => import('../features/Landing/Landing'));
const Login = React.lazy(() => import('../features/authentication/Login'));
const Restaurant = React.lazy(() => import('../features/restaurant/components/Restaurant'));
const Dashboard = React.lazy(() => import('../features/admin/dashboard/Dashboard'));

function PublicWrapper() {
  const params = useParams();
  const { restaurantId } = params;
  const [query] = useSearchParams();
  const tableNumber = query.get('tableNumber');

  useClientSocket(
    restaurantId ? Number(restaurantId) : undefined,
    tableNumber ? Number(tableNumber) : undefined
  );
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

const routes: RouteObject[] = [
  {
    path: RouterNames.HOME,
    element: <PublicWrapper />,
    children: [
      {
        path: RouterNames.HOME,
        element: (
          <Suspense fallback={<SuspenseLoader />}>
            <Landing />
          </Suspense>
        )
      },
      {
        path: '/login',
        element: <Navigate to={RouterNames.LOGIN} replace />
      },
      {
        path: RouterNames.HOME_RESTAURANT,
        element: (
          <Suspense fallback={<SuspenseLoader />}>
            <Landing />
          </Suspense>
        )
      },
      {
        path: RouterNames.RESTAURANT,
        element: (
          <Suspense fallback={<SuspenseLoader />}>
            <Restaurant />
          </Suspense>
        )
      },
      {
        path: RouterNames.RESTAURANT_REDIRECT,
        element: (
          <Suspense fallback={<SuspenseLoader />}>
            <RestaurantRedirect />
          </Suspense>
        )
      },
      {
        path: '/admin',
        element: <AuthWrapper />,
        children: [
          {
            path: '/admin/',
            element: <Navigate to="/admin/dashboard" replace />
          },
          {
            path: `${RouterNames.LOGIN}?/*`,
            element: (
              <Suspense fallback={<SuspenseLoader />}>
                <Login />{' '}
              </Suspense>
            )
          },
          {
            path: ':option',
            element: (
              <PrivateRoute>
                <Suspense fallback={<SuspenseLoader />}>
                  <Dashboard />
                </Suspense>
              </PrivateRoute>
            )
          }
        ]
      }
    ]
  },

  {
    path: '*',
    element: <ErrorPage />
  }
];

const router = createBrowserRouter(routes);

export function Router() {
  return <RouterProvider router={router} />;
}
