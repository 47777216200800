import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';

export const anymenuTheme = {
  typography: {
    allVariants: {
      fontFamily: `Poppins,sans-serif`,
    }
  },
  palette: {
    primary: {
      // light: '#a38ec1',
      main: '#664399',
      // dark: '#3d285b'
    },
    secondary: {
      main: '#FF5722'
    },
  },
  th: {
    color: "#664399",
    borderRadius: '20px'
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          background: 'transparent!important',
          border: 'none'
        },
      }
    },
  }
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const theme = createTheme(anymenuTheme);
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
