import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Order, Restaurant } from '../../api';
import { RootState } from '../../store';
import { ApiStatus } from '../../utils/models/ApiStatus';
import { getItemsForRestaurant, getRestaurants } from './api/restaurantsApi';

interface RestaurantState {
  status: ApiStatus;
  items: Restaurant[];
  selectedRestaurantId?: number;
  ordersAccepted: Order | null;
  acceptWaiter: number | null;
  acceptReceipt: number | null;
}

const initialState: RestaurantState = {
  status: ApiStatus.IDLE,
  items: [],
  ordersAccepted: null,
  acceptWaiter: null,
  acceptReceipt: null
};

export const getRestaurantItemsAsync = createAsyncThunk(
  'restaurant/getItems',
  async ({ id, lang }: { id: number; lang: string }) => {
    const response = await getItemsForRestaurant(id, lang);
    return response;
  }
);

export const getRestaurantsAsync = createAsyncThunk('restaurant/getRestaurants', getRestaurants);
const restaurantSlice = createSlice({
  name: 'Restaurant Slice',
  initialState,
  reducers: {
    clearError: (state) => {
      state.status = ApiStatus.IDLE;
    },
    setActiveRestaurantId: (state, { payload }: PayloadAction<number>) => {
      state.selectedRestaurantId = payload;
    },
    setAcceptedOrder: (state, { payload }: PayloadAction<Order | null>) => {
      state.ordersAccepted = payload;
    },
    notifyClientReceipt: (state, { payload }: PayloadAction<any>) => {
      state.acceptReceipt = payload;
    },
    notifyClientAcceptWaiter: (state, { payload }: PayloadAction<any>) => {
      state.acceptWaiter = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRestaurantsAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(getRestaurantsAsync.fulfilled, (state, { payload }) => {
      state.status = ApiStatus.IDLE;
      state.items = payload;
    });
    builder.addCase(getRestaurantsAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
    });
  }
});

export const {
  clearError,
  setActiveRestaurantId,
  setAcceptedOrder,
  notifyClientAcceptWaiter,
  notifyClientReceipt
} = restaurantSlice.actions;

export const selectRestaurantStatus = ({ restaurants }: RootState): ApiStatus => restaurants.status;
export const selectRestaurants = ({ restaurants }: RootState): Restaurant[] => restaurants.items;
export const selectActiveRestaurantId = ({ restaurants }: RootState): number | undefined =>
  restaurants.selectedRestaurantId;
export const selectActiveRestaurantInfo = createSelector(
  [selectRestaurants, selectActiveRestaurantId],
  (restaurants, activeId) => {
    return restaurants.find((restaurant) => restaurant.id === activeId);
  }
);

export const selectClientsOrdersAccepted = ({ restaurants }: RootState): any =>
  restaurants.ordersAccepted;
export const selectClientsAcceptReceipt = ({ restaurants }: RootState): any =>
  restaurants.acceptReceipt;
export const selectClientsAcceptWaiter = ({ restaurants }: RootState): any =>
  restaurants.acceptWaiter;

export default restaurantSlice.reducer;
