import { CreateRestaurantDto, ProductListDto, ReserveTableDTO, Restaurant } from '../../../api';
import { restaurantsApi } from '../../../config/apiClient';

export const getItemsForRestaurant = async (id: number, lang: string): Promise<ProductListDto> => {
  const response = await restaurantsApi.restaurantsControllerGetRestaurantItems({ id, lang });
  return response;
};

export const getRestaurants = async (): Promise<Restaurant[]> => {
  const response = await restaurantsApi.restaurantsControllerGetRestaurants();
  return response;
};

export const getRestaurantInfo = async (id: number): Promise<Restaurant> => {
  const response = await restaurantsApi.restaurantsControllerGetRestaurantById({ id });
  return response;
};

export const updateRestaurantInfo = async (
  id: number,
  data: CreateRestaurantDto
): Promise<number> => {
  const response = await restaurantsApi.restaurantsControllerUpdateRestaurant({
    id,
    createRestaurantDto: data
  });
  return response;
};

export const reserveTable = async (id: number, data: ReserveTableDTO): Promise<string> => {
  const response = await restaurantsApi.restaurantsControllerReserveTable({
    id,
    reserveTableDTO: data
  });
  return response;
};
