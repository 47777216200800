import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActiveTablesDto, Order, Restaurant } from '../../../api';
import { RootState } from '../../../store';
import { ApiStatus } from '../../../utils/models/ApiStatus';
import {
  notifyClientAcceptWaiter,
  notifyClientOrderStatus,
  notifyClientWaiterDeliveredTable
} from '../../order/api/ordersApi';
import { getRestaurantInfo } from '../../restaurant/api/restaurantsApi';

interface AdminState {
  status: ApiStatus;
  restaurantInfo?: Restaurant;
  tableOrders: Record<number, Order[] | null>;
  waiterCalls: Record<number, boolean>;
  receiptCalls: Record<number, number>;
}

const initialState: AdminState = {
  status: ApiStatus.IDLE,
  tableOrders: {},
  waiterCalls: {},
  receiptCalls: {}
};

export const fetchRestaurantInfoAsync = createAsyncThunk(
  'admin/fetchRestaurantInfo',
  getRestaurantInfo
);

export const notifyClientOrderStatusAsync = createAsyncThunk(
  'orders/notifyClient',
  notifyClientOrderStatus
);

export const notifyClientAcceptWaiterCallAsync = createAsyncThunk(
  'orders/notifyClientAcceptWaiterCall',
  notifyClientAcceptWaiter
);

export const notifyClientWaiterDeliveredTableAsync = createAsyncThunk(
  'orders/notifyClientWaiterDeliveredTable',
  async ({ tableNumber, code }: { tableNumber: number; code: string }) => {
    const response = await notifyClientWaiterDeliveredTable(tableNumber, code);
    return response;
  }
);

const adminSlice = createSlice({
  name: 'Admin State',
  initialState,
  reducers: {
    receiveOrderFromSocket: (state, { payload }: PayloadAction<Order>) => {
      if (
        state.tableOrders?.[payload.tableNumber] &&
        state.tableOrders[payload.tableNumber] !== null
      ) {
        if (state.tableOrders[payload.tableNumber]?.find((o) => o.id === payload.id)) {
          return;
        }
        state.tableOrders[payload.tableNumber] = [
          ...state.tableOrders[payload.tableNumber],
          payload
        ];
      } else {
        state.tableOrders[payload.tableNumber] = [payload];
      }
    },
    resetTableOrders: (state, { payload: tableNumber }: PayloadAction<number[]>) => {
      const newTableOrders = Object.keys(state.tableOrders)
        .filter((k) => !tableNumber.includes(Number(k)))
        .map((k) => state.tableOrders[k]);
      state.tableOrders = newTableOrders;
    },
    deleteOrder: (state, { payload }: PayloadAction<{ tableNumber: number }>) => {
      state.tableOrders[payload.tableNumber] = [];
    },
    updateOrder: (state, { payload }: PayloadAction<{ order: Order; tableNumber: number }>) => {
      if (state?.tableOrders?.[payload.tableNumber]) {
        const index = state.tableOrders[payload.tableNumber]?.findIndex(
          (o) => o.id === payload.order.id
        );
        if (index !== -1) {
          state.tableOrders[payload.tableNumber][index] = payload.order;
        }
      }
    },
    setTableOrders: (
      state,
      { payload }: PayloadAction<{ tableOrders: Record<number, Order[] | null> }>
    ) => {
      state.tableOrders = payload.tableOrders;
    },
    notifyWaiter: (state, { payload }: PayloadAction<number>) => {
      state.waiterCalls[payload] = true;
    },
    acceptWaiterCall: (state, { payload }: PayloadAction<number>) => {
      state.waiterCalls[payload] = false;
    },
    notifyWaiterReceipt: (state, { payload }: PayloadAction<number>) => {
      state.receiptCalls[payload] = payload;
    },
    acceptWaiterReceipt: (state, { payload }: PayloadAction<number>) => {
      state.receiptCalls[payload] = null;
    },
    tableStatusUpdate: (state, { payload }: PayloadAction<ActiveTablesDto[]>) => {
      if (state.restaurantInfo) {
        state.restaurantInfo.activeTables = payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRestaurantInfoAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(fetchRestaurantInfoAsync.fulfilled, (state, { payload }) => {
      state.status = ApiStatus.IDLE;
      state.restaurantInfo = payload;
    });
    builder.addCase(fetchRestaurantInfoAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
    });
  }
});

export const {
  receiveOrderFromSocket,
  tableStatusUpdate,
  acceptWaiterCall,
  notifyWaiter,
  notifyWaiterReceipt,
  acceptWaiterReceipt,
  deleteOrder,
  resetTableOrders,
  updateOrder,
  setTableOrders
} = adminSlice.actions;

export default adminSlice.reducer;

export const selectAdminStatus = ({ admin }: RootState): ApiStatus => admin.status;
export const selectRestaurantInfo = ({ admin }: RootState): Restaurant | undefined =>
  admin.restaurantInfo;

export const selectTableOrders = ({ admin }: RootState): Record<number, Order[] | null> =>
  admin.tableOrders;
export const selectWaiterCalls = ({ admin }: RootState): Record<number, boolean> =>
  admin.waiterCalls;
export const selectWaiterReceipt = ({ admin }: RootState): Record<number, number> =>
  admin.receiptCalls;
