import { Middleware } from '@reduxjs/toolkit';
import { submitCartAsync } from '../features/order/ordersSlice';
import SnackbarUtils from '../utils/snackbar/SnackbarUtils';

export const snackbarMiddleware: Middleware = () => {
  return (next) => (action) => {
    if (submitCartAsync.fulfilled.match(action)) {
      SnackbarUtils.success('Order created!');
    }

    if (submitCartAsync.rejected.match(action)) {
      if (action.error.code === 'ERR_BAD_REQUEST') {
        SnackbarUtils.error('No items in the order, please add items');
      } else {
        SnackbarUtils.error('We are sorry, your order could not be created.');
      }
    }

    next(action);
  };
};
