import {
  AuthenticationApi,
  CloudinaryApi,
  OrdersApi,
  ProductsApi,
  RestaurantsApi,
  UsersApi
} from '../api';
import config from './apiConfig';

export const productsApi = new ProductsApi(config);
export const authenticationApi = new AuthenticationApi(config);
export const ordersApi = new OrdersApi(config);
export const restaurantsApi = new RestaurantsApi(config);
export const usersApi = new UsersApi(config);
export const cloudinaryApi = new CloudinaryApi(config);
