/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateProductDto,
  ProductListDto,
} from '../models';
import {
    CreateProductDtoFromJSON,
    CreateProductDtoToJSON,
    ProductListDtoFromJSON,
    ProductListDtoToJSON,
} from '../models';

export interface ProductsControllerCreateProductRequest {
    createProductDto: CreateProductDto;
}

export interface ProductsControllerDeleteProductRequest {
    id: number;
}

export interface ProductsControllerMassImportProductsRequest {
    requestBody: Array<string>;
}

export interface ProductsControllerUpdateProductRequest {
    id: number;
    createProductDto: CreateProductDto;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async productsControllerCreateProductRaw(requestParameters: ProductsControllerCreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.createProductDto === null || requestParameters.createProductDto === undefined) {
            throw new runtime.RequiredError('createProductDto','Required parameter requestParameters.createProductDto was null or undefined when calling productsControllerCreateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductDtoToJSON(requestParameters.createProductDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async productsControllerCreateProduct(requestParameters: ProductsControllerCreateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.productsControllerCreateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsControllerDeleteProductRaw(requestParameters: ProductsControllerDeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsControllerDeleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productsControllerDeleteProduct(requestParameters: ProductsControllerDeleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productsControllerDeleteProductRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productsControllerGetProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     */
    async productsControllerGetProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListDto> {
        const response = await this.productsControllerGetProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productsControllerMassImportProductsRaw(requestParameters: ProductsControllerMassImportProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling productsControllerMassImportProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products/mass-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productsControllerMassImportProducts(requestParameters: ProductsControllerMassImportProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productsControllerMassImportProductsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productsControllerUpdateProductRaw(requestParameters: ProductsControllerUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsControllerUpdateProduct.');
        }

        if (requestParameters.createProductDto === null || requestParameters.createProductDto === undefined) {
            throw new runtime.RequiredError('createProductDto','Required parameter requestParameters.createProductDto was null or undefined when calling productsControllerUpdateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductDtoToJSON(requestParameters.createProductDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async productsControllerUpdateProduct(requestParameters: ProductsControllerUpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.productsControllerUpdateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
