/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status: OrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    tableNumber: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    triggerNumber: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    triggerSector: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    comment: string;
    /**
     * 
     * @type {object}
     * @memberof Order
     */
    additionalProperties: object;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    restaurantId: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    restaurant: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Order
     */
    items?: Array<Product>;
}


/**
 * @export
 */
export const OrderStatusEnum = {
    Created: 'created',
    Preparation: 'preparation',
    Delivery: 'delivery',
    Delivered: 'delivered',
    All: 'all',
    Closed: 'closed'
} as const;
export type OrderStatusEnum = typeof OrderStatusEnum[keyof typeof OrderStatusEnum];


/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "tableNumber" in value;
    isInstance = isInstance && "triggerNumber" in value;
    isInstance = isInstance && "triggerSector" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "additionalProperties" in value;
    isInstance = isInstance && "restaurantId" in value;
    isInstance = isInstance && "restaurant" in value;

    return isInstance;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
        'tableNumber': json['tableNumber'],
        'triggerNumber': json['trigger_number'],
        'triggerSector': json['trigger_sector'],
        'code': json['code'],
        'comment': json['comment'],
        'additionalProperties': json['additional_properties'],
        'restaurantId': json['restaurantId'],
        'restaurant': json['restaurant'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ProductFromJSON)),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
        'tableNumber': value.tableNumber,
        'trigger_number': value.triggerNumber,
        'trigger_sector': value.triggerSector,
        'code': value.code,
        'comment': value.comment,
        'additional_properties': value.additionalProperties,
        'restaurantId': value.restaurantId,
        'restaurant': value.restaurant,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ProductToJSON)),
    };
}

