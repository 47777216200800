import { ProductListDto } from '../../../api';
import { restaurantsApi } from '../../../config/apiClient';

export const getProductsForRestaurant = async (
  restaurantId: number,
  lang: string
): Promise<ProductListDto> => {
  const response = await restaurantsApi.restaurantsControllerGetRestaurantItems({
    id: restaurantId,
    lang
  });
  return response;
};
