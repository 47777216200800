import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { anymenuTheme } from '@root/ui/theming/theme';
import { generateCodeId } from '@root/utils/code';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store';
import { getRestaurantInfo, reserveTable } from '../api/restaurantsApi';
import { selectActiveRestaurantInfo, setActiveRestaurantId } from '../restaurantSlice';

const StyledBoxWrapper = styled(Box)(() => ({
  position: 'relative',
  backgroundColor: 'white'
}));

const StyledLockWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '10%',
  borderRadius: `${anymenuTheme.th.borderRadius}`,
  background: '#ffffff',
  minWidth: '30%',
  width: '90%',
  height: '250px',
  left: '5%'
}));

const StyledBluryBG = styled(Box)(() => ({
  background: `url(https://res.cloudinary.com/hy4epz6jm/image/upload/v1678537262/cld-sample-4.jpg) no-repeat center center fixed`,
  filter: 'blur(8px)',
  height: '100%'
}));

const StyledTypographyHeader = styled(Typography)(() => ({
  background: '#664399',
  width: '100%',
  borderRadius: `${anymenuTheme.th.borderRadius} ${anymenuTheme.th.borderRadius} 0px 0px`,
  padding: '2%',
  textAlign: 'center',
  color: '#fff'
}));

const StyledBoxCentral = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '50%',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '30px'
}));

function CodeInput({ tableNumber, restaurant }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputCode, setInputCode] = useState('');
  const [tableCodeActive, setTableCodeActive] = useState(false);
  return (
    <>
      <StyledTypographyHeader variant="h6">{t('redirectEnterCode')}</StyledTypographyHeader>
      <StyledBoxCentral>
        <TextField
          sx={{
            width: '100%',
            margin: '0px 25px'
          }}
          type="text"
          label="code"
          name="code"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target && event.target.value) {
              setInputCode(event.target.value);
            }
          }}
        />
        <Button
          variant="contained"
          sx={{ minWidth: '150px', m: '15px' }}
          onClick={() => {
            const foundTable = (restaurant?.activeTables as any[]).find(
              (item) => item.table == tableNumber
            );
            if (inputCode !== '' && foundTable) {
              if (foundTable.code == inputCode) {
                setTableCodeActive(true);
                navigate(
                  `/restaurants/${restaurant.id}?tableNumber=${tableNumber}&code=${foundTable.code}`
                );
              } else {
                setTableCodeActive(false);
              }
            }
          }}>
          {t('redirectOpenWithCode')}
        </Button>
        {!tableCodeActive && (
          <Typography sx={{ m: '15px', color: 'red' }}>{t('redirectEnterValidCode')}</Typography>
        )}
      </StyledBoxCentral>
    </>
  );
}

function OpenTable({ restaurant, tableNumber }) {
  const codeRef = useRef(generateCodeId(5));
  const code = codeRef.current;
  const navigate = useNavigate();
  const [isFromLandingTableNumber, setisFromLandingTableNumber] = useState(1);

  const updateActiveTables = () => {
    reserveTable(restaurant.id, { tableNumber, code }).then(() => {
      navigate(`/restaurants/${restaurant.id}?tableNumber=${tableNumber}&code=${code}`);
    });
  };
  const { t } = useTranslation();
  const activeRestaurant = useAppSelector(selectActiveRestaurantInfo);
  const isFromLanding = +tableNumber == -1;

  return (
    <>
      <StyledTypographyHeader variant="h6">
        {isFromLanding ? (
          <Box>Please select table</Box>
        ) : (
          <>{t('redirectYourCodeIs', { code: code })}</>
        )}
      </StyledTypographyHeader>
      <StyledBoxCentral>
        {isFromLanding ? (
          <Box>
            <Select
              sx={{
                background: 'white',
                margin: '0px 15px 0px 0px',
                color: 'black'
              }}
              value={isFromLandingTableNumber}
              onChange={({ target }) => {
                setisFromLandingTableNumber(+target.value);
              }}>
              {[...Array(activeRestaurant ? activeRestaurant.numberOfTables : 0).keys()].map(
                (restaurant) =>
                  restaurant > 0 && <MenuItem value={restaurant}>Table {restaurant}</MenuItem>
              )}
            </Select>
            <Button
              variant="contained"
              sx={{ minWidth: '150px' }}
              onClick={() => {
                navigate({
                  pathname: `/restaurants/redirect`,
                  search: `?tableNumber=${isFromLandingTableNumber}&restaurant=${activeRestaurant?.id}`
                });
                window.location.reload();
              }}>
              Go to table {isFromLandingTableNumber}
            </Button>
          </Box>
        ) : (
          <Button variant="contained" sx={{ minWidth: '150px' }} onClick={updateActiveTables}>
            {t('redirectOpenTable')}
          </Button>
        )}
      </StyledBoxCentral>
    </>
  );
}

const RestaurantRedirect = (): JSX.Element => {
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const tableNumber = Number(query.get('tableNumber'));
  const restaurantId = Number(query.get('restaurant'));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (restaurantId) {
      // Not sure what this does
      dispatch(setActiveRestaurantId(+restaurantId));
    }
  }, [restaurantId]);

  const {
    isLoading,
    data: restaurant,
    isError
  } = useQuery({
    queryKey: ['restaurants', restaurantId],
    queryFn: () => getRestaurantInfo(Number(restaurantId))
  });
  const foundTable = (restaurant?.activeTables as any[])?.find((item) => item.table == tableNumber);
  const isOpen = foundTable?.active ?? false;
  let content;
  if (isLoading) {
    content = (
      <CircularProgress
        sx={{
          margin: '0 auto',
          marginTop: '50px',
          display: 'block'
        }}
      />
    );
  } else if (isError) {
    content = (
      <Typography variant="h5" sx={{ p: 2, textAlign: 'center' }}>
        Restaurant not found
      </Typography>
    );
  } else if (!isOpen) {
    content = <OpenTable restaurant={restaurant} tableNumber={tableNumber} />;
  } else {
    content = <CodeInput restaurant={restaurant} tableNumber={tableNumber} />;
  }
  return (
    <StyledBoxWrapper>
      <StyledBluryBG />
      {restaurant?.isclosed ? (
        <Backdrop sx={{ zIndex: 999 }} open={true}>
          <Typography variant="h5" sx={{ color: '#fff', p: 2, textAlign: 'center' }}>
            {t('restaurantClosed')}
          </Typography>
        </Backdrop>
      ) : (
        <StyledLockWrapper>{content}</StyledLockWrapper>
      )}
    </StyledBoxWrapper>
  );
};

export default RestaurantRedirect;
