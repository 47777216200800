import './i18n/config';
import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { SnackbarProvider } from './context/snackbar';
import { store } from './store';
import { ThemeProvider } from './ui/theming/theme';
import { initSentry } from './utils/sentry';
import { SnackbarUtilsConfigurator } from './utils/snackbar/SnackbarUtils';

const queryClient = new QueryClient();

initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <SnackbarProvider>
          <SnackbarUtilsConfigurator />
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
