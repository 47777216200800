import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';

function PrivateRoute({ children }: { children: React.ReactElement }): JSX.Element {
  return (
    <>
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn redirectUrl={window.location.pathname} />
      </SignedOut>
    </>
  );
}

export default PrivateRoute;
