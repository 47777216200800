import { CreateOrderAPIDto, Order, OrderStatusEnum } from '../../../api';
import { ordersApi } from '../../../config/apiClient';

export const submitCart = async (order: CreateOrderAPIDto): Promise<number> => {
  const response = await ordersApi.ordersControllerCreateOrder({ createOrderAPIDto: order });
  return response;
};

export const fetchActiveOrder = async (
  tableNumber: number,
  restaurantId: number,
  code: string
): Promise<Order[]> => {
  const response = await ordersApi.ordersControllerGetActiveOrderByTableNumber({
    tableNumber,
    id: restaurantId,
    code
  });
  return response;
};

export const notifyWaiter = async (tableNumber: number): Promise<unknown> => {
  const response = await ordersApi.ordersControllerNotifyWaiter({
    id: tableNumber,
    ordersControllerNotifyWaiterRequest: {
      status: OrderStatusEnum.Created
    }
  });
  return response;
};

export const notifyWaiterReceiptCall = async (tableNumber: number): Promise<unknown> => {
  const response = await ordersApi.ordersControllerNotifyWaiterReceiptCall({
    id: tableNumber
  });
  return response;
};

export const notifyClientOrderStatus = async (orderId: number): Promise<unknown> => {
  const response = await ordersApi.ordersControllerNotifyClientOrderStatus({ id: orderId });
  return response;
};

export const notifyClientAcceptWaiter = async (tableId: number): Promise<unknown> => {
  const response = await ordersApi.ordersControllerNotifyClientAcceptWaiter({ id: tableId });
  return response;
};

export const notifyClientWaiterDeliveredTable = async (
  tableId: number,
  code = ''
): Promise<unknown> => {
  const response = await ordersApi.ordersControllerNotifyClientWaiterDeliveredTable({
    id: tableId,
    code
  });
  return response;
};
