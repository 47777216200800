/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateRestaurantDto,
  Order,
  ProductListDto,
  ReserveTableDTO,
  Restaurant,
} from '../models';
import {
    CreateRestaurantDtoFromJSON,
    CreateRestaurantDtoToJSON,
    OrderFromJSON,
    OrderToJSON,
    ProductListDtoFromJSON,
    ProductListDtoToJSON,
    ReserveTableDTOFromJSON,
    ReserveTableDTOToJSON,
    RestaurantFromJSON,
    RestaurantToJSON,
} from '../models';

export interface RestaurantsControllerCreateRestaurantRequest {
    createRestaurantDto: CreateRestaurantDto;
}

export interface RestaurantsControllerDeleteRestaurantRequest {
    id: number;
}

export interface RestaurantsControllerGetRestaurantByIdRequest {
    id: number;
}

export interface RestaurantsControllerGetRestaurantItemsRequest {
    id: number;
    lang: string;
}

export interface RestaurantsControllerGetRestaurantOrdersRequest {
    id: number;
    orderStatus: string;
}

export interface RestaurantsControllerReserveTableRequest {
    id: number;
    reserveTableDTO: ReserveTableDTO;
}

export interface RestaurantsControllerUpdateRestaurantRequest {
    id: number;
    createRestaurantDto: CreateRestaurantDto;
}

/**
 * 
 */
export class RestaurantsApi extends runtime.BaseAPI {

    /**
     */
    async restaurantsControllerCreateRestaurantRaw(requestParameters: RestaurantsControllerCreateRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.createRestaurantDto === null || requestParameters.createRestaurantDto === undefined) {
            throw new runtime.RequiredError('createRestaurantDto','Required parameter requestParameters.createRestaurantDto was null or undefined when calling restaurantsControllerCreateRestaurant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/restaurants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRestaurantDtoToJSON(requestParameters.createRestaurantDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async restaurantsControllerCreateRestaurant(requestParameters: RestaurantsControllerCreateRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.restaurantsControllerCreateRestaurantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerDeleteRestaurantRaw(requestParameters: RestaurantsControllerDeleteRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerDeleteRestaurant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/restaurants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async restaurantsControllerDeleteRestaurant(requestParameters: RestaurantsControllerDeleteRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.restaurantsControllerDeleteRestaurantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async restaurantsControllerGetRestaurantByIdRaw(requestParameters: RestaurantsControllerGetRestaurantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Restaurant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerGetRestaurantById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/restaurants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestaurantFromJSON(jsonValue));
    }

    /**
     */
    async restaurantsControllerGetRestaurantById(requestParameters: RestaurantsControllerGetRestaurantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Restaurant> {
        const response = await this.restaurantsControllerGetRestaurantByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerGetRestaurantItemsRaw(requestParameters: RestaurantsControllerGetRestaurantItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerGetRestaurantItems.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling restaurantsControllerGetRestaurantItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/restaurants/{id}/items/{lang}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductListDtoFromJSON(jsonValue));
    }

    /**
     */
    async restaurantsControllerGetRestaurantItems(requestParameters: RestaurantsControllerGetRestaurantItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductListDto> {
        const response = await this.restaurantsControllerGetRestaurantItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerGetRestaurantOrdersRaw(requestParameters: RestaurantsControllerGetRestaurantOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Order>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerGetRestaurantOrders.');
        }

        if (requestParameters.orderStatus === null || requestParameters.orderStatus === undefined) {
            throw new runtime.RequiredError('orderStatus','Required parameter requestParameters.orderStatus was null or undefined when calling restaurantsControllerGetRestaurantOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.orderStatus !== undefined) {
            queryParameters['orderStatus'] = requestParameters.orderStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/restaurants/{id}/orders`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     */
    async restaurantsControllerGetRestaurantOrders(requestParameters: RestaurantsControllerGetRestaurantOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Order>> {
        const response = await this.restaurantsControllerGetRestaurantOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerGetRestaurantsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Restaurant>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/restaurants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RestaurantFromJSON));
    }

    /**
     */
    async restaurantsControllerGetRestaurants(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Restaurant>> {
        const response = await this.restaurantsControllerGetRestaurantsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerReserveTableRaw(requestParameters: RestaurantsControllerReserveTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerReserveTable.');
        }

        if (requestParameters.reserveTableDTO === null || requestParameters.reserveTableDTO === undefined) {
            throw new runtime.RequiredError('reserveTableDTO','Required parameter requestParameters.reserveTableDTO was null or undefined when calling restaurantsControllerReserveTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/restaurants/{id}/reserve-table`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReserveTableDTOToJSON(requestParameters.reserveTableDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async restaurantsControllerReserveTable(requestParameters: RestaurantsControllerReserveTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.restaurantsControllerReserveTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async restaurantsControllerUpdateRestaurantRaw(requestParameters: RestaurantsControllerUpdateRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling restaurantsControllerUpdateRestaurant.');
        }

        if (requestParameters.createRestaurantDto === null || requestParameters.createRestaurantDto === undefined) {
            throw new runtime.RequiredError('createRestaurantDto','Required parameter requestParameters.createRestaurantDto was null or undefined when calling restaurantsControllerUpdateRestaurant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/restaurants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRestaurantDtoToJSON(requestParameters.createRestaurantDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async restaurantsControllerUpdateRestaurant(requestParameters: RestaurantsControllerUpdateRestaurantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.restaurantsControllerUpdateRestaurantRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
