import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AppBar, Box, Chip, styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import SnackbarUtils from '../../utils/snackbar/SnackbarUtils';
import { Lang } from '../Lang/Lang';

const LeftHeader = styled(Box)(({ theme }) => ({
  background: 'white  ',
  borderRadius: '0px 0px 10px 0px',
  height: '90px',
  display: 'flex',
  alignItems: 'center'
}));
const RightHeader = styled(Box)(({ theme }) => ({
  background: 'white',
  borderRadius: '0px 0px 0px 10px',
  height: '90px',
  display: 'flex',
  alignItems: 'center'
}));

export function Header() {
  const [query] = useSearchParams();
  const tableCode = query.get('code');
  return (
    <AppBar
      position="relative"
      sx={{
        zIndex: '12',
        background: 'rgb(240 240 240 / 25%)',
        boxShadow: 'none'
      }}>
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          backgroundColor: 'white'
        }}>
        <LeftHeader>
          <img alt="Anymenu logo" style={{ width: '150px' }} src="/logo.png" />
        </LeftHeader>
        <Box
          display="flex"
          height="100%"
          padding={1}
          alignItems="center"
          alignContent="center"
          sx={{
            background: 'white'
          }}>
          {tableCode && (
            <Chip
              icon={<ContentCopyIcon color="white" />}
              sx={{
                backgroundColor: '#FF5722',
                color: 'white'
              }}
              label={tableCode}
              clickable
              onClick={(e) => {
                navigator.clipboard.writeText(tableCode);
                SnackbarUtils.success('Copied to clipboard');
              }}
            />
          )}
        </Box>
        <RightHeader>
          <Lang />
        </RightHeader>
      </Box>
    </AppBar>
  );
}
