import * as Sentry from '@sentry/react';

export function initSentry() {
  if (process.env.NODE_ENV === 'production') {
    console.log('Sentry Enabled');
    Sentry.init({
      dsn: 'https://73a57823669bacaba9a508233e85338c@o4506758252986368.ingest.sentry.io/4506758254034944',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      environment: import.meta.env.VITE_ENV || 'development',
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/app.any-menu\.com\/api/,
        /^https:\/\/staging.app.any-menu\.com\/api/
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    console.log('Sentry is off for non-prod environment.');
  }
}
